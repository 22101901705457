import React from "react"

const DisplayOpp = ({ logo, opp_name, location}) => {

    if (location === 'A') {
        
        return (
            <table style={{textAlign:'center', tableLayout:'fixed'}}>
                <tbody>
                    <tr>
                        <td style={{width:'10px', color:'#b8b8b8'}}>
                            @
                        </td>
                        <td>
                            <img
                                height={'25px'}
                                src={logo}
                                alt={opp_name}>
                            </img>
                        </td>
                    </tr>
                </tbody>
            </table>
        )

    } else {

        return (
            <table style={{textAlign:'center', tableLayout:'fixed'}}>
                <tbody>
                    <tr>
                        <td style={{width:'10px'}}>
                            <span style={{whiteSpace:'pre'}}>    </span>
                        </td>
                        <td>
                            <img
                                height={'25px'}
                                src={logo}
                                alt={opp_name}>
                            </img>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

}

export default DisplayOpp