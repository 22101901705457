import React from "react"

const DisplayWL = ({ result}) => {

    const winColor = '#00b374'
    const lossColor = '#dd0051'
    const pushColor = '#7d7d7d'


    if (result === 'W') {
        
        return (

            <span style={{color:winColor, fontWeight:'bold'}}>{result}</span>

        )
    
    } else if (result === 'L') {

        return (

            <span style={{color:lossColor, fontWeight:'bold'}}>{result}</span>
            
        )

    } else {

        return (

            <span style={{color:pushColor, fontWeight:'bold'}}>{result}</span>
            
        )

    }

}

export default DisplayWL