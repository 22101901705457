import React from 'react'
import { VictoryLine, VictoryChart, VictoryAxis, VictoryLegend, VictoryVoronoiContainer, VictoryTooltip } from 'victory'



const NfeloChart = ( { data, teamColor } ) => {

    const chartData = data.allGameHistoryCsv.nodes.map(node => {

        return (
            {
                week: parseInt(node.week),
                nfelo: parseInt(node.nfelo),
                nfelo_raw: parseInt(node.nfelo_raw),
                nfeloBest : parseInt(node.league_best_nfelo),
                nfeloWorst : parseInt(node.league_worst_nfelo),
                nfeloMiddle : 1505,
                opp: node.opp,
                result: node.result,
                nfelo_change: parseInt(node.nfelo_change),
            }
        )
    
    })

    const chartDataAux = data.allGameHistoryCsv.nodes.map(node => {

        return (
            {
                week: parseInt(node.week),
                nfelo: parseInt(node.nfelo),
                nfeloBest : parseInt(node.league_best_nfelo),
                nfeloWorst : parseInt(node.league_worst_nfelo),
                nfeloMiddle : 1505
            }
        )
    
    })

    return (
        <VictoryChart
            domainPadding={{y:[15,15]}}
            width={550}
            height={300}
            containerComponent={
                <VictoryVoronoiContainer
                    voronoiBlacklist={['leagueMax','leagueMin','leagueAvg','nfeloRaw']}
                    labels={({ datum }) => `${datum.result} vs ${datum.opp}:  ${datum.nfelo_change}`}
                    labelComponent={
                        <VictoryTooltip
                            flyoutStyle={{
                                fill: 'white',
                            }}
                            style={{fill: teamColor}}
                        />
                    }
                />
              }
            >
            <VictoryLegend
                orientation='horizontal'
                x={175}
                y={10}
                symbolSpacer={6}
                data={[
                    {
                        name: 'nfelo',
                        strokeColor : teamColor,
                        strokeOpac : 1,
                        strokeDash : 0,
                    },
                    {
                        name: 'nfelo (ex QB adj)',
                        strokeColor : teamColor,
                        strokeOpac : .2,
                        strokeDash : 0,
                    },
                    {
                        name: 'League Best/Worst',
                        strokeColor : '#000000',
                        strokeOpac : .5,
                        strokeDash : 3,
                    }
                ]}
                style={{
                    data: {
                        fill : 'none',
                        stroke : ({datum}) => datum.strokeColor,
                        opacity : ({datum}) => datum.strokeOpac,
                        strokeDasharray : ({datum}) => datum.strokeDash,
                    },
                    labels: {
                        fill : ({datum}) => datum.strokeColor,
                        opacity : ({datum}) => datum.strokeOpac,
                        fontSize: 10,
                    }
                }}
            />
            <VictoryAxis
                label='Week'
                style={{
                    axisLabel: {
                        fontSize: '12px',
                        padding: '30',
                    },
                    tickLabels: {
                        fontSize: '10px',
                        color: '#808080',
                    }
                }}
            />
            <VictoryAxis
                dependentAxis
                style={{
                    tickLabels: {
                        fontSize: '10px',
                        color: '#808080',
                    }
            }}
            />
            <VictoryLine
                style={{
                    data: {stroke : teamColor},
                    labels: {
                        fontSize : 12
                    }
                }}
                data={chartData}
                x='week'
                y='nfelo'
            />
            <VictoryLine
                name='nfeloRaw'
                style={{
                    data: {
                        stroke : teamColor,
                        opacity : .2,
                        strokeWidth: 1,
                    }
                }}
                data={chartData}
                x='week'
                y='nfelo_raw'
            />
            <VictoryLine
                name='leagueMax'
                style={{
                    data: {
                        stroke: '#000000',
                        strokeDasharray: 3,
                        opacity: .5,
                        strokeWidth: 1,
                    }
                }}
                data={chartDataAux}
                x='week'
                y='nfeloBest'
            />
            <VictoryLine
                name='leagueMin'
                style={{
                    data: {
                        stroke: '#000000',
                        strokeDasharray: 3,
                        opacity: .5,
                        strokeWidth: 1,
                    }
                }}
                data={chartDataAux}
                x='week'
                y='nfeloWorst'
            />
            <VictoryLine
                name='leagueAvg'
                style={{
                    data: {
                        stroke : '#000000',
                        strokeWidth: 1,
                    }
                }}
                data={chartDataAux}
                x='week'
                y='nfeloMiddle'
            />
        </VictoryChart>
    )

}

export default NfeloChart