const DisplayEpa = ({ data, pos }) => {

        if (pos === 'qb') {
            return 'Total Epa: ' + parseFloat(data.teamsCsv.pass_leader_epa).toFixed(1)
        } if (pos === 'rec') {
            return 'Total Epa: ' + parseFloat(data.teamsCsv.rec_leader_epa).toFixed(1)
        } else {
            return 'Total Epa: ' + parseFloat(data.teamsCsv.rush_leader_epa).toFixed(1)
        }

}

export default DisplayEpa