import React from 'react'
import { Link } from "gatsby"
import tinycolor from 'tinycolor2'

import DisplayResult from '../../../helper_js/display_game_result'
import DisplayWL from '../../../helper_js/display_win_loss'
import DisplayOpp from '../../../helper_js/display_opponent'

import gameStyles from './team_games.module.scss'

const TeamGameRow = ( { opp, game_id, home_spread_line, points_for, points_against, result, spread_result, location, blended_margin, blended_margin_delta, nfelo_change, logo} ) => {


        const neg_color = '#ffabc0'
        const zero_color = '#ffffff'
        const pos_text = '#65e0b7'

        var deltaColor = ''
        var nfeloColor = ''

        if (blended_margin_delta <= 0) {
            deltaColor = tinycolor.mix(zero_color, neg_color, Math.abs(blended_margin_delta) * 25)
        } else {
            deltaColor = tinycolor.mix(zero_color, pos_text, Math.abs(blended_margin_delta) * 25)
        }

        if (nfelo_change <= 0) {
            nfeloColor = tinycolor.mix(zero_color, neg_color, Math.abs(nfelo_change) * 10)
        } else {
            nfeloColor = tinycolor.mix(zero_color, pos_text, Math.abs(nfelo_change) * 10)
        }

        return (
            <tr className={gameStyles.gameRow}>    
                <td width={"10%"}>
                    <Link className={gameStyles.oppGameLink} to={`/games/${game_id}/`}>
                        <div className={gameStyles.linkDiv} style={{paddingTop:'0px', paddingBottom:'0px'}}>
                            <DisplayOpp
                                logo={logo}
                                opp_name={opp}
                                location={location}
                            />
                        </div>
                    </Link>
                </td>
                <td width={"21%"}>
                    <Link className={gameStyles.oppGameLink} to={`/games/${game_id}/`}>
                        <div className={gameStyles.linkDiv}>
                            <DisplayResult
                                result={result}
                                pf={points_for}
                                pa={points_against}
                            />
                        </div>
                    </Link>
                </td>
                <td width={"9%"} className={gameStyles.gameBorderRight}>
                    <Link className={gameStyles.oppGameLink} to={`/games/${game_id}/`}>
                        <div className={gameStyles.linkDiv}>
                            {parseInt(points_for - points_against)}
                        </div>
                    </Link>
                </td>
                <td width={"9%"}>
                    <Link className={gameStyles.oppGameLink} to={`/games/${game_id}/`}>
                        <div className={gameStyles.linkDiv}>
                            {home_spread_line}
                        </div>
                    </Link>
                </td>
                <td className={gameStyles.gameBorderRight} width={"9%"}>
                    <Link className={gameStyles.oppGameLink} to={`/games/${game_id}/`}>
                        <div className={gameStyles.linkDiv}>
                            <DisplayWL
                                result={spread_result}
                            />
                        </div>
                    </Link>
                </td>
                <td width={"11.5%"}>
                    <Link className={gameStyles.oppGameLink} to={`/games/${game_id}/`}>
                        <div className={gameStyles.linkDiv}>
                            {Math.round(10*blended_margin)/10}
                        </div>
                    </Link>
                </td>
                <td className={gameStyles.gameBorderRight} width={"10%"} style={{color : deltaColor,}}>
                    <Link className={gameStyles.oppGameLink} to={`/games/${game_id}/`}>
                        <div className={gameStyles.linkDiv}>
                            <span style={{color : deltaColor,}}>{Math.round(10*blended_margin_delta)/10}</span>
                        </div>
                    </Link>
                </td>
                <td width={"12.5%"}>
                    <Link className={gameStyles.oppGameLink} to={`/games/${game_id}/`}>
                        <div className={gameStyles.linkDiv}>
                            <span style={{color : nfeloColor}}>{parseInt(nfelo_change)}</span>
                        </div>
                    </Link>
                </td>
            </tr>
        )

}

export default TeamGameRow


