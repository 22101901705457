import React from 'react'

import NfeloChart from './nfelo_chart'

import teamChartStyles from './team_nfelo_chart.module.scss'



const TeamNfeloChart = (props) => {

    return (
        <div className={teamChartStyles.mainContainer}>
            <h1 className={teamChartStyles.chartTitle}>nfelo</h1>
            <h2 className={teamChartStyles.chartSubTitle}>This chart shows the team's nfelo relative to the best and worst teams in the league (dotted lines). The model mid-point (1505) represents an average team</h2>
            <div className={teamChartStyles.chartContainer}>
                <NfeloChart
                    data={props.data}
                    teamColor={props.data.teamsCsv.team_color}
                />
            </div>
        </div>
    )
}

export default TeamNfeloChart