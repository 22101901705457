import React from 'react'

import DisplayEpaRank from '../../../helper_js/display_epa_ranks'
import PassLine from './display_pass_line'
import NonPassLine from './display_non_pass_line'
import DisplayEpa from './display_epa'

import teamStyles from './team_summary.module.scss'


const TeamSummary = (props) => {

    return (
        <div className={teamStyles.mainContainer}>
            <div className={teamStyles.headlineContainer}>
                <div className={teamStyles.headlineBox}>
                    <table className={teamStyles.nameTable}>
                        <tbody>
                            <tr>
                                <td rowSpan={2} className={teamStyles.logo_td} width={"30%"}>
                                    <div className={teamStyles.logo_box}>
                                        <img
                                            className={teamStyles.team_logo}
                                            src={props.data.teamsCsv.team_logo_espn}
                                            alt={props.data.teamsCsv.team_nick}>
                                        </img>
                                    </div>
                                </td>
                                <td className={teamStyles.teamName}>
                                    {props.data.teamsCsv.team_nick}
                                </td>
                            </tr>
                            <tr>
                                <td className={teamStyles.record}>
                                    {parseInt(props.data.teamsCsv.wins)} - {parseInt(props.data.teamsCsv.losses)} - {parseInt(props.data.teamsCsv.draws)} 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={teamStyles.headlineDivider}></div>
                <div className={teamStyles.headlineBox}>
                    <table className={teamStyles.rankingTable}>
                        <thead>
                            <tr className={teamStyles.rankHeader}>
                                <td>
                                    nfelo
                                </td>
                                <td>
                                    Net EPA
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={teamStyles.rankRow}>
                                <td>
                                    {parseInt(props.data.teamsCsv.nfelo)} ({parseInt(props.data.teamsCsv.nfelo_rank)})
                                </td>
                                <td>
                                    {Math.round(100*props.data.teamsCsv.net_epa_per_play_ytd)/100} ({parseInt(props.data.teamsCsv.net_epa_per_play_ytd_rank)})
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={teamStyles.headlineDivider}></div>
                <div className={teamStyles.headlineBox}>
                    <table className={teamStyles.epaTable}>
                        <thead>
                            <tr>
                                <td className={teamStyles.epaHeaderTableName} width={"20%"}>
                                </td>
                                <td className={teamStyles.epaHeader} width={"40%"}>
                                    Off
                                </td>
                                <td className={teamStyles.epaHeader} width={"40%"}>
                                    Def
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                        <tr className={teamStyles.epaRowTotal}>
                                <td className={teamStyles.epaTableRowHeader}>
                                    Total
                                </td>
                                <td className={teamStyles.epaTableItem}>
                                    {DisplayEpaRank(props.data.teamsCsv.epa_per_play_ytd_rank)}
                                </td>
                                <td className={teamStyles.epaTableItem}>
                                    {DisplayEpaRank(props.data.teamsCsv.epa_per_play_against_ytd_rank)}
                                </td>
                            </tr>
                            <tr className={teamStyles.epaRowSub}>
                                <td className={teamStyles.epaTableRowHeader}>
                                    Pass
                                </td>
                                <td className={teamStyles.epaTableItem}>
                                    {DisplayEpaRank(props.data.teamsCsv.epa_per_pass_ytd_rank)}
                                </td>
                                <td className={teamStyles.epaTableItem}>
                                    {DisplayEpaRank(props.data.teamsCsv.epa_per_pass_against_ytd_rank)}
                                </td>
                            </tr>
                            <tr className={teamStyles.epaRowSub}>
                                <td className={teamStyles.epaTableRowHeader}>
                                    Run
                                </td>
                                <td className={teamStyles.epaTableItem}>
                                    {DisplayEpaRank(props.data.teamsCsv.epa_per_rush_ytd_rank)}
                                </td>
                                <td className={teamStyles.epaTableItem}>
                                    {DisplayEpaRank(props.data.teamsCsv.epa_per_rush_against_ytd_rank)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={teamStyles.horizontalDivider}></div>
            <div className={teamStyles.leaderContainer}>
            <div className={teamStyles.leaderBox}>
                    <div className={teamStyles.leaderType}>
                        Passing
                    </div>
                    <div className={teamStyles.player_icon_div}>
                        <div className={teamStyles.player_icon_container}>
                            <img
                                className={teamStyles.player_icon}
                                src={props.data.teamsCsv.pass_leader_headshot}
                                alt={props.data.teamsCsv.pass_leader}>
                            </img>
                        </div>
                    </div>
                    <div className={teamStyles.player_stat_div}>
                        <p className={teamStyles.player_name}>
                            {props.data.teamsCsv.pass_leader}
                        </p>
                        <p className={teamStyles.player_stat}>
                            <PassLine data={props.data}/>
                        </p>
                        <p className={teamStyles.player_stat}>
                            <DisplayEpa data={props.data} pos={'qb'}/>
                        </p>
                    </div>
                </div>
                <div className={teamStyles.leaderBox}>
                    <div className={teamStyles.leaderType}>
                        Receiving
                    </div>
                    <div className={teamStyles.player_icon_div}>
                        <div className={teamStyles.player_icon_container}>
                            <img
                                className={teamStyles.player_icon}
                                src={props.data.teamsCsv.rec_leader_headshot}
                                alt={props.data.teamsCsv.rec_leader}>
                            </img>
                        </div>
                    </div>
                    <div className={teamStyles.player_stat_div}>
                        <p className={teamStyles.player_name}>
                            {props.data.teamsCsv.rec_leader}
                        </p>
                        <p className={teamStyles.player_stat}>
                            <NonPassLine data={props.data} pos={'rec'}/>
                        </p>
                        <p className={teamStyles.player_stat}>
                            <DisplayEpa data={props.data} pos={'rec'}/>
                        </p>
                    </div>
                </div>
                <div className={teamStyles.leaderBox}>
                <div className={teamStyles.leaderType}>
                        Rushing
                    </div>
                    <div className={teamStyles.player_icon_div}>
                        <div className={teamStyles.player_icon_container}>
                            <img
                                className={teamStyles.player_icon}
                                src={props.data.teamsCsv.rush_leader_headshot}
                                alt={props.data.teamsCsv.rush_leader}>
                            </img>
                        </div>
                    </div>
                    <div className={teamStyles.player_stat_div}>
                        <p className={teamStyles.player_name}>
                            {props.data.teamsCsv.rush_leader}
                        </p>
                        <p className={teamStyles.player_stat}>
                            <NonPassLine data={props.data} pos={'rush'}/>
                        </p>
                        <p className={teamStyles.player_stat}>
                            <DisplayEpa data={props.data} pos={'rush'}/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default TeamSummary