import React from 'react'
import { VictoryLine, VictoryChart, VictoryAxis, VictoryLegend } from 'victory'



const RollingPlaysChart = ( { data, teamColor, teamColor2 } ) => {

    const chartData = data.allRollingPlaysCsv.nodes.map(node => {

            if (parseInt(node.play_number) < 250) {
                return (
                    {
                        play_number: parseInt(node.play_number),
                        off_epa: null,
                        def_epa: null,
                        net_epa: null,
                        zero_point: 0
                    }
                )
            } else {
                return(
                    {
                        play_number: parseInt(node.play_number),
                        off_epa: parseInt(100*node.rolling_off_epa)/100,
                        def_epa: parseInt(100*node.rolling_def_epa)/100,
                        net_epa: parseInt(100*node.net_epa)/100,
                        zero_point: 0
                    }
                )
            }
    })


    return (
        <VictoryChart
            domainPadding={{y:[15,15]}}
            width={550}
            height={300}
            >
            <VictoryLegend
                orientation='horizontal'
                x={175}
                y={10}
                symbolSpacer={6}
                data={[
                    {
                        name: 'Net EPA',
                        strokeColor : teamColor,
                        strokeOpac : 1,
                        strokeDash : 0,
                    },
                    {
                        name: 'Off Epa',
                        strokeColor : teamColor,
                        strokeOpac : 1,
                        strokeDash : 3,
                    },
                    {
                        name: 'Def EPA',
                        strokeColor : teamColor2,
                        strokeOpac : 1,
                        strokeDash : 3,
                    }
                ]}
                style={{
                    data: {
                        fill : 'none',
                        stroke : ({datum}) => datum.strokeColor,
                        opacity : ({datum}) => datum.strokeOpac,
                        strokeDasharray : ({datum}) => datum.strokeDash,
                    },
                    labels: {
                        fill : ({datum}) => datum.strokeColor,
                        opacity : ({datum}) => datum.strokeOpac,
                        fontSize: 10,
                    }
                }}
            />
            <VictoryAxis
                label='Play Number'
                offsetY={49}
                style={{
                    axisLabel: {
                        fontSize: '12px',
                        padding: '30',
                    },
                    tickLabels: {
                        fontSize: '10px',
                        color: '#808080',
                    }
                }}
            />
            <VictoryAxis
                dependentAxis
                style={{
                    tickLabels: {
                        fontSize: '10px',
                        color: '#808080',
                    }
            }}
            />
            <VictoryLine
                style={{
                    data: {stroke : teamColor},
                    labels: {
                        fontSize : 12
                    }
                }}
                data={chartData}
                x='play_number'
                y='net_epa'
            />
            <VictoryLine
                name='Off EPA'
                style={{
                    data: {
                        stroke : teamColor,
                        opacity : 1,
                        strokeWidth: 1,
                        strokeDasharray: 3,
                    }
                }}
                data={chartData}
                x='play_number'
                y='off_epa'
            />
            <VictoryLine
                name='Def EPA'
                style={{
                    data: {
                        stroke : teamColor2,
                        opacity : 1,
                        strokeWidth: 1,
                        strokeDasharray: 3,
                    }
                }}
                data={chartData}
                x='play_number'
                y='def_epa'
            />
            <VictoryLine
                name='Zero Line'
                style={{
                    data: {
                        stroke : 'black',
                        strokeWidth: 1,
                    }
                }}
                data={chartData}
                x='play_number'
                y='zero_point'
            />
        </VictoryChart>
    )

}

export default RollingPlaysChart