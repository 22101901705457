

const NonPassLine = ({ data, pos }) => {

    var tries = 0
    var yds = 0
    var tds = 0

    if (pos === 'rec') {
        
        tries = parseInt(data.teamsCsv.rec_leader_rec)
        yds = parseInt(data.teamsCsv.rec_leader_rec_yards)
        tds = parseInt(data.teamsCsv.rec_leader_tds)

        if (tds === 0) {
            tds = ''
        } else {
            tds = tds + ' TDs'
        }
    
        return tries + ' RECs, ' + yds +', ' + tds 

    } else {

        tries = parseInt(data.teamsCsv.rush_leader_rush)
        yds = parseInt(data.teamsCsv.rush_leader_rush_yards)
        tds = parseInt(data.teamsCsv.rush_leader_tds)

        if (tds === 0) {
            tds = ''
        } else {
            tds = tds + ' TDs'
        }

        return tries + ' CARs, ' + yds +', ' + tds 
        
    }

}

export default NonPassLine