import React from "react"
import tinycolor from 'tinycolor2'

const DisplayEpaRank = (rank) => {

    const neg_text = '#c22d52'
    const grey_color = '#6e6e6e'
    const pos_text = '#26b588'

    var rankStyle = {}

    if (rank >= 17) {
        rankStyle = {
            color : tinycolor.mix(grey_color, neg_text, 100*Math.abs(rank-16)/16),
        }
    } else {
        rankStyle = {
            color : tinycolor.mix(grey_color, pos_text, 100*Math.abs(17-rank)/16),
        }
    }
    
    return (
        <div style={rankStyle}>
            {Math.round(rank)}
        </div>
    )
}

export default DisplayEpaRank