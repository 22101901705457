import React from 'react'
import { Link } from "gatsby"

import mainStyles from '../team_page.module.scss'

const SeasonalLink = ( { data, linkType}) => {

    var linkPath = ''

    if (linkType === 'previous') {

        if (parseInt(data.teamsCsv.season) === 2009) {

            return (
                <div className={mainStyles.backButtonDiv}>
                </div>
            )

        } else {

            linkPath = '/teams/' + data.teamsCsv.team.toLowerCase() + '/' + (parseInt(data.teamsCsv.season)-1 + '/')

            return (
                <div className={mainStyles.backButtonDiv}>
                    <Link to={linkPath} style={{color:data.teamsCsv.team_color, textDecoration:"none"}}>Previous</Link>
                </div>
            )    

        }

    } else {

        // needs to be made dynamic at some point... //
        if (parseInt(data.teamsCsv.season) === 2023) {

            return(
                <div className={mainStyles.backButtonDiv}>
                </div>
            )
        
        } else {

            linkPath = '/teams/' + data.teamsCsv.team.toLowerCase() + '/' + (parseInt(data.teamsCsv.season)+1 +'/')

            return (
                <div className={mainStyles.backButtonDiv}>
                    <Link to={linkPath} style={{color:data.teamsCsv.team_color, textDecoration:"none"}}>Next</Link>
                </div>
            )    
        }

    }

}

export default SeasonalLink