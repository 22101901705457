import React from 'react'

import TeamGameRow from './team_game_row'

import gameStyles from './team_games.module.scss'


const TeamGames = (props) => {

    return (
        <div className={gameStyles.mainContainer}>
            <div className={gameStyles.gameTableContainer}>
                <table className={gameStyles.gameTable}>
                    <thead>
                        <tr className={gameStyles.gameHeader}>
                            <th>
                                Opp
                            </th>
                            <th>
                                Result
                            </th>
                            <th>
                                Margin
                            </th>
                            <th colSpan={2}>
                                Spread
                            </th>
                            <th>
                                Blended Dif
                            </th>
                            <th>
                                Delta
                            </th>
                            <th>
                                nfelo Change
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.allGameHistoryCsv.nodes.map(node => {
                            return (
                                <TeamGameRow
                                    key={node.game_id}
                                    opp={node.opp}
                                    location={node.location}
                                    game_id={node.game_id_slug}
                                    home_spread_line={node.home_spread_line}
                                    points_for={node.points_for}
                                    points_against={node.points_against}
                                    result={node.result}
                                    spread_result={node.spread_result}
                                    blended_margin={node.blended_margin}
                                    blended_margin_delta={node.blended_margin_delta}
                                    nfelo={node.neflo}
                                    nfelo_change={node.nfelo_change}
                                    logo={node.team_logo_espn}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )

}

export default TeamGames