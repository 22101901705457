

const PassLine = ({ data }) => {

    var comp = parseInt(data.teamsCsv.pass_leader_comp)
    var att = parseInt(data.teamsCsv.pass_leader_att)
    var yds = parseInt(data.teamsCsv.pass_leader_yards)
    var tds = parseInt(data.teamsCsv.pass_leader_tds)
    var ints = parseInt(data.teamsCsv.pass_leader_ints)

    if (tds === 0) {
        tds = ''
    } else {
        tds = tds + ' TD, '
    }

    if (ints === 0) {
        ints = ''
    } else {
        ints = ints + ' INT'
    }

    return comp + '-' + att + ', ' + yds + ', ' + tds + ints

}

export default PassLine