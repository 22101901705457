import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import Head from '../../components/head'
import SeasonalLink from './team_components/seasonal_link'
import TeamSummary from './team_components/team_summary'
import TeamNfeloChart from './team_components/team_nfelo_chart'
import TeamPlayChart from './team_components/team_epa_chart'
import TeamGames from './team_components/team_games'

import mainStyles from './team_page.module.scss'


export const query = graphql`
    query (
        $team: String!
        $season: String! 
    ) {
        teamsCsv (
            team : {
                eq : $team
            }
            season : {
                eq : $season
            }
        ) {
            season
            team
            nfelo
            GP
            wins
            losses
            draws
            points_for_ytd
            points_against_ytd
            point_dif_ytd
            epa_per_play_ytd
            epa_per_pass_ytd
            epa_per_rush_ytd
            epa_per_play_against_ytd
            epa_per_pass_against_ytd
            epa_per_rush_against_ytd
            net_epa_per_play_ytd
            pythag_wins
            nfelo_rank
            point_dif_ytd_rank
            net_epa_per_play_ytd_rank
            epa_per_play_ytd_rank
            epa_per_pass_ytd_rank
            epa_per_rush_ytd_rank
            epa_per_play_against_ytd_rank
            epa_per_pass_against_ytd_rank
            epa_per_rush_against_ytd_rank
            pass_leader
            pass_leader_id
            pass_leader_att
            pass_leader_comp
            pass_leader_yards
            pass_leader_tds
            pass_leader_ints
            pass_leader_epa
            rec_leader
            rec_leader_id
            rec_leader_rec
            rec_leader_rec_yards
            rec_leader_tds
            rec_leader_epa
            rush_leader
            rush_leader_id
            rush_leader_rush
            rush_leader_rush_yards
            rush_leader_tds
            rush_leader_epa
            pass_leader_headshot
            rec_leader_headshot
            rush_leader_headshot
            team_nick
            team_logo_espn
            team_color
            team_color2
        }
        allGameHistoryCsv(
            sort: {
                fields: game_id,
                order: DESC
            }
            filter: {
              team: {
                in: [$team]
              }
              season: {
                in: [$season]
              }
              week : {
                  nin: ["18","19","20","21"]
              }
            }
        ) {
            nodes {
                game_id
                game_id_slug
                season
                week
                team
                opp
                location
                points_for
                points_against
                home_spread_line
                result
                spread_result
                blended_margin
                blended_margin_delta
                nfelo
                nfelo_raw
                nfelo_change
                league_best_nfelo
                league_worst_nfelo
                team_logo_espn
            }
        }
        allRollingPlaysCsv(
            filter: {
              team: {
                in: [$team]
              }
              season: {
                in: [$season]
              }
            }
        ) {
            nodes {
                season
                team
                play_number
                rolling_off_epa
                rolling_def_epa
                net_epa
            }
        }
    }
`

const TeamPage = (props) => {

    return (
        <Layout>
            <Head title={`${props.data.teamsCsv.season} ${props.data.teamsCsv.team_nick}`}
                pageDescription={`NFL analytics summary for the ${props.data.teamsCsv.season} ${props.data.teamsCsv.team_nick}`}/>
            <div className={mainStyles.teamContainer}>
                <div className={mainStyles.seasonalLinkContainer}>
                    <SeasonalLink data={props.data} linkType={'previous'} />
                    <SeasonalLink data={props.data} linkType={'next'} />
                </div>
                <TeamSummary
                    data={props.data}
                />
                <TeamNfeloChart
                    data={props.data}
                />
                <TeamPlayChart
                    data={props.data}
                />
                <TeamGames
                    data={props.data}
                />
            </div>

        </Layout>
    )
}

export default TeamPage