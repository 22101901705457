import React from 'react'

import RollingPlaysChart  from './rolling_epa_chart'

import teamChartStyles from './team_nfelo_chart.module.scss'



const TeamPlayChart = (props) => {

    return (
        <div className={teamChartStyles.mainContainer}>
            <h1 className={teamChartStyles.chartTitle}>Rolling EPA</h1>
            <h2 className={teamChartStyles.chartSubTitle}>This chart shows the team's 250 play rolling offensive and defensive EPA. Net EPA is the difference between the two</h2>
            <div className={teamChartStyles.chartContainer}>
                <RollingPlaysChart
                    data={props.data}
                    teamColor={props.data.teamsCsv.team_color}
                    teamColor2={props.data.teamsCsv.team_color2}
                />
            </div>
        </div>
    )
}

export default TeamPlayChart